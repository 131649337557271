export type Locale = 'en' | 'pl';

const translations = {
  nav_home: ['Home', 'Home'],
  nav_app: ['Aplikacja', 'Application'],
  nav_about: ['O nas', 'About us'],
  nav_contact: ['Kontakt', 'Contact'],
  nav_login: ['Zaloguj się', 'Login'],
  nav_download: ['Pobierz', 'Download'],
  nav_agility: ['Agility', 'Agility'],
  nav_logout: ['Wyloguj się', 'Logout'],
  nav_applicationPageTitle: ['Aplikacja', 'Application'],
  nav_agilityPageTitle: ['Zawody Agility', 'Agility Competitions'],
  nav_offer: ['Oferta dla trenerów', 'Offer'],
  nav_workWithUs: ['Współpraca', 'Work with us'],
  nav_pricing: ['Cennik', 'Pricing'],
  nav_createClub: ['Załóż klub', 'Create club'],
  footer: {
    competitions: ['Zawody agility', 'Agility competitions'],
    privacyPolicy: ['Polityka prywatności', 'Privacy policy'],
    appTerms: ['Regulamin prowadzenia klubu', 'Club terms and conditions'],
  },
  downloadPage: {
    title: ['Pobierz', 'Download'],
    trainWithUs: ['Trenuj razem z nami', 'Train with us'],
    subheading: [
      'Zapisz się na zajęcia z posłuszeństwa, znajdź trenera Agility,',
      'Signup to obedience classes, find an Agility trainer,',
    ],
    subheadingContinued: [
      'Frisbee czy Flyball, zapisz się na psie przedszkole, lub przyjdź na seminarium!',
      'Frisbee or Flyball, sign up for dog kindergarten, or come to a seminar!',
    ],
    forAndroid: ['Aplikacja dostępna na Androida', 'Application available for Android'],
    forApple: ['Aplikacja dostępna na iOS', 'Application available for iOS'],
  },
  agility: {
    title: ['Zawody Agility', 'Agility Competitions'],
    heading: [
      'Tworzymy również system do organizacji zawodów Agility',
      'We are also creating a system for organizing dog agility competitions',
    ],
    appLinkSubtitle: [
      'Aplikacja dostępna jest pod adresem:',
      'The app is available under the link:',
    ],
  },
  contact: {
    title: ['Kontakt', 'Contact'],
    getInTouch: ['Napisz do nas', 'Get in touch'],
    getInTouchDescription: [
      'Masz sugestie? Chcesz nawiązać współpracę? Brakuje funkcjonalności? Zapraszamy do kontaktu!',
      'Do you have any suggestions? Do you want to cooperate? Is there a lack of functionality? Contact us!',
    ],
    contactMeByFacebook: ['Skontaktuj się z nami przez Facebooka', 'Contact us via Facebook'],
  },
  aboutUs: {
    content: [
      'Dnia 6 marca 2017 roku adoptowałem Mongo - półrocznego psiaka ze schroniska. Od razu otrzymałem kontakt do behawiorysty, dzięki któremu nauczyliśmy się podstawowych komend i posłuszeństwa. Po zakończeniu psiego przedszkola chciałem jednak dalej pracować z psem - Mongo się świetnie bawił, a ja zdałem sobie sprawę, że moja aktywność z psem nie musi się ograniczać do wyjścia na spacer. Znalezienie trenera okazało się czymś absurdalnie trudnym - nie wiedziałem, gdzie moglibyśmy kontynuować naukę lub spędzać aktywnie czas. Nawet kiedy udało mi się zdobyć kontakt do jakiegoś trenera, to często okazywało się, że nie jesteśmy na odpowiednim poziomie nauki albo po prostu nie ma dostępnych terminów. Pomimo tego, że ćwiczymy już od paru lat, umówienie się na zajęcia nawet z osobą, z którą już ćwiczyliśmy jest wyzwaniem. Aktywne spędzanie czasu z psem świetnie buduje relację pomiędzy opiekunem a zwierzakiem i chciałbym aby każdy miał taką możliwość. Agilix ma zwiększyć przezroczystość rynku związanego z psimi aktywnościami: każdy może umówić się na wybrane zajęcia a trenerzy są w stanie zarządzać swoimi wydarzeniami.',
      "On March 6, 2017, I adopted Mongo - a six-month-old puppy from the shelter. Immediately, I received contact to a behaviorist, thanks to whom we learned basic commands and obedience. After finishing dog kindergarten, I wanted to continue working with the dog - Mongo had a great time, and I realized that my activity with the dog didn't have to be limited to going for a walk. Finding a trainer turned out to be absurdly difficult - I didn't know where we could continue learning or spend time actively. Even when I managed to get contact with a trainer, it often turned out that we were not at the right learning level or there were simply no available dates. Despite the fact that we have been practicing for several years, scheduling classes even with a person with whom we have already practiced is a challenge. Spending active time with the dog greatly builds the relationship between the guardian and the animal and I would like everyone to have such an opportunity. Agilix aims to increase the transparency of the market related to dog activities: everyone can sign up for selected classes and trainers are able to manage their events.",
    ],
    whoAreWe: ['Kim jesteśmy?', 'Who are we?'],
    whoAreWeAnswered: [
      'Jesteśmy miłośnikami zwierząt z misją',
      'We are animal lovers with a mission',
    ],
    adam: ['Zawodnik agility, programista', 'Agility competitor, software developer'],
    renata: ['Zawodnik hoopers, tester oprogramowania', 'Hoopers competitor, software tester'],
    natalia: ['Trener Agility, specjalista PR', 'Agility trainer, PR specialist'],
  },
  aboutPageTitle: ['Nasza misja', 'Our mission'],
  loginPageTitle: ['Login', 'Login'],
  loginPage: {
    title: ['Zaloguj się', 'Login'],
  },
  offerPageTitle: ['Oferta Agilix', 'Agilix offer'],
  pageUnderConstruction: ['Strona w budowie', 'Page under construction'],
  home: {
    pageTitle: [
      'Otwórz się na nowe aktywności ze swoim psem',
      'Open up to new activities with your dog',
    ],
    subtitle: [
      'Apka dla Ciebie i Twojego najlepszego przyjaciela',
      'App for you and your best friend',
    ],
    description: [
      'Zapisz się na zajęcia z posłuszeństwa, znajdź trenera Agility, Frisbee czy Flyball, zapisz się na psie przedszkole, lub przyjdź na seminarium!',
      'Signup to obedience classes, find an Agility trainer, Frisbee or Flyball, sign up for dog kindergarten, or come to a seminar!',
    ],
    invite: ['Masz zaproszenie na zajęcia', 'You have an invitation to the classes'],
  },
  hoopersLevelOne: [
    'Instruktor Hoopers Polska poziomu pierwszego',
    'Hoopers Polska level one instructor',
  ],
  hoopersLevelTwo: [
    'Instruktor Hoopers Polska poziomu drugiego',
    'Hoopers Polska level two instructor',
  ],
  hoopersJudgeLevelOne: [
    'Sędzia Hoopers Polska poziomu pierwszego',
    'Hoopers Polska level one judge',
  ],
  hoopersJudgeLevelTwo: [
    'Sędzia Hoopers Polska poziomu drugiego',
    'Hoopers Polska level two judge',
  ],
  obtainedCertifications: ['Uzyskane certyfikaty', 'Obtained certifications'],
  listOfCertifiedTrainers: ['Lista certyfikowanych trenerów', 'List of certified trainers'],
  whereToBookClasses: ['Gdzie zapisać się na zajęcia?', 'Where to book classes?'],
  seeMoreOn: ['Zobacz więcej na ', 'See more on '],
  layoutDescription: [
    'Aplikacja dla Ciebie i Twojego najlepszego przyjaciela',
    'App for you and your best friend',
  ],
  hoopersPageTitle: ['Hoopers Polska', 'Hoopers Polska'],
  hoopersPageDescription: ['Lista certyfikowanych trenerów', 'List of certified trainers'],
  noCertificates: ['Brak certyfikatów', 'No certificates'],
};

export function t(key: string, locale: Locale): string {
  if (key.includes('.')) {
    const [first, second] = key.split('.');

    if (!first) {
      return '--missing--';
    }

    // @ts-ignore
    const firstValue = translations[first];

    if (!first) {
      return '--missing--';
    }

    const secondValue = firstValue[second];

    if (!secondValue) {
      return '--missing--';
    }

    if (locale === 'pl') {
      return secondValue[0];
    }
    return secondValue[1];
  }
  // @ts-ignore
  const value = translations[key];

  if (!value) {
    return '--missing--';
  }

  if (locale === 'pl') {
    return value[0];
  }
  return value[1];
}
