type Props = {
  className?: string;
  fullClass?: string;
};

export default function ChevronDown({ className, fullClass }: Props) {
  const c = fullClass ?? `h-6 w-6 ${className}` ?? '';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={c}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}
